.msg {
  padding: 5px 10px;
  background: $primary;
  color: white;
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  margin: 0;

  &__input {
    margin-top: -20px;
    margin-bottom: 20px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  &__error {
    background: $bad;
  }
}
