.sidebar {
  background: rgba($dark-grey, 1);
  min-height: 100vh;
  pointer-events: none;
  z-index: 99;

  @include media-breakpoint-down(md) {
    position: fixed;
    top: 0;
    left: 0;
    background: none;
  }

  .back-btn {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 4;
    height: 55px;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    top: env(safe-area-inset-top);

    @include media-breakpoint-up(lg) {
      display: none;
    }

    svg {
      height: 21px;
    }
  }

  &--inner {
    pointer-events: all;
    display: flex;
    justify-content: center;
    background: $black;
    padding-top: env(safe-area-inset-top);

    @include media-breakpoint-up(lg) {
      display: block;
      background: none;
    }

    .logo {
      display: inline-flex;
      align-items: flex-start;
      height: 55px;
      padding: 16px 10px;

      pointer-events: all;

      @include media-breakpoint-up(lg) {
        display: flex;
        align-items: flex-start;
        padding: 0px 20px;
        flex-direction: column;
        justify-content: center;
        height: 70px;
      }

      .logo-text {
        text-transform: uppercase;
        font-size: 32px;
        line-height: 1;
        color: white;
        font-family: "Khula", sans-serif;
        margin: 0;

        @include media-breakpoint-up(lg) {
          font-size: 32px;
          margin-top: 10px;
        }

        span {
          color: orange;
        }
      }
    }
  }

  &--scroll {
    max-height: calc(100vh - 68px + env(safe-area-inset-bottom));
    overflow-y: hidden;
    width: 100%;
    background: $dark-grey;
    // min-height: calc(100vh - 68px);
    pointer-events: all;
    -ms-overflow-style: none;
    box-shadow: 2px 0px 14px 0px rgba(0, 0, 0, 0.34);
    position: fixed;
    bottom: 0;
    left: 0;
    height: 50px;
    height: calc(50px + env(safe-area-inset-bottom));
    display: flex;
    justify-content: space-between;
    padding-bottom: env(safe-area-inset-bottom);
    // padding-bottom: 20px;

    &::-webkit-scrollbar {
      display: none;
    }

    @include media-breakpoint-up(lg) {
      height: auto;
      width: auto;
      background: none;
      max-height: calc(100vh - 88px);
      min-height: initial;
      box-shadow: none;
      position: static;
      display: block;
      overflow-y: auto;
    }
  }

  &--item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: solid 4px transparent;

    @include media-breakpoint-up(lg) {
      padding-left: 20px;
      border-top: none;
      border-right: solid 4px transparent;
    }

    p {
      font-size: 16px;
      color: white;
      font-weight: 500;
      margin: 0;
      display: none;

      @include media-breakpoint-up(lg) {
        display: block;
      }
    }

    .icon {
      padding: 20px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50px;

      @include media-breakpoint-up(lg) {
        padding: 20px 0;
        width: 60px;
      }

      svg {
        height: 20px;

        @include media-breakpoint-up(lg) {
          height: 20px;
        }

        path {
          fill: $grey-light;
        }
      }
    }

    &.user-profile {
      display: none;
      .thumb {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        background-color: rgba(255, 255, 255, 0.2);
        border-radius: 50px;
        height: 30px;
        width: 30px;

        @include media-breakpoint-up(lg) {
          height: 30px;
          width: 30px;
        }
      }

      p {
        max-width: 150px;
        overflow: hidden;
      }

      .icon {
        padding: 10px 0;
      }

      @include media-breakpoint-up(lg) {
        display: flex;
        .icon {
          padding: 15px 0;
        }
      }
    }

    &:hover {
      background: rgba(0, 0, 0, 0.2);
      border-top: solid 4px rgba(255, 255, 255, 0.2);

      @include media-breakpoint-up(lg) {
        border-top: none;
        border-right: solid 4px rgba(255, 255, 255, 0.2);
      }
    }

    &.active {
      background: $black;
      border-top: solid 4px $primaryDark;

      @include media-breakpoint-up(lg) {
        border-top: none;
        border-right: solid 4px $primaryDark;
      }

      .icon {
        svg path {
          fill: $primary;
        }
      }

      p {
        color: $primary;
      }
    }
  }

  &--user-mob {
    position: fixed;
    top: 0;
    right: 0;
    border: none !important;
    height: 55px;
    width: auto;
    z-index: 5;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 20px;
    cursor: pointer;
    top: env(safe-area-inset-top);

    .icon {
      padding: 0;

      .thumb {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        background-color: rgba(255, 255, 255, 0.2);
        border-radius: 50px;
        height: 30px;
        width: 30px;

        @include media-breakpoint-up(lg) {
          height: 30px;
          width: 30px;
        }
      }
    }

    @include media-breakpoint-up(lg) {
      display: none;
    }
  }

  &--version {
    position: absolute;
    left: 20px;
    bottom: 20px;
    margin: 0;
    font-size: 10px;
    line-height: 1;
    display: none;

    @include media-breakpoint-up(lg) {
      display: block;
    }
  }
}
