.styled-input {
  &--select {
    background: rgba($grey-medium, 0.3);
    height: 40px;
    display: flex;
    align-items: center;
    padding: 0 10px;
    margin-bottom: 20px;
    border-radius: 3px;
    border: solid 1px rgba($grey-medium, 0.5) !important;
    color: white;

    &.disabled {
      opacity: 0.5;
      cursor: no-drop;

      select {
        pointer-events: none;
      }
    }

    select {
      appearance: none;
      background: none;
      color: white;
      font-size: 16px;
      outline: none !important;
      border: none;
    }

    option {
      color: black;
      background: white;
    }
  }

  &--textarea {
    background: rgba($grey-medium, 0.3);
    height: 120px;
    min-height: 120px;
    padding: 10px;
    color: white;
    border: solid 1px rgba($grey-medium, 0.5) !important;
    border-radius: 3px;
    outline: none !important;
    font-size: 16px;
    min-width: 100%;
    max-width: 100%;
  }

  &--input {
    background: rgba($grey-medium, 0.3);
    height: 40px;
    padding: 0 10px;
    margin-bottom: 20px;
    border-radius: 3px;
    color: white;
    line-height: 40px;
    font-size: 16px;
    border: solid 1px rgba($grey-medium, 0.5) !important;
    outline: none !important;
  }
}
