.spinner {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;

	svg {
		width: 50px;
		height: 50px;
	}
}
