.person {
  &--wrap {
    position: relative;
    img {
      width: 100%;
    }
  }

  &--banner {
    position: absolute;
    top: 0;
    left: 0;
    height: 235px;
    width: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    opacity: 0.8;
    overflow: hidden;
    filter: grayscale(1) brightness(0.5);
    transform: translateZ(0);
    top: -25px;
    left: -20px;
    right: -20px;
    width: calc(100% + 40px);

    @include media-breakpoint-up(md) {
      background-position: 50% 200%;
      background-size: 100% auto;
      height: calc(50vh - 65px);
      background-attachment: fixed;
    }

    @include media-breakpoint-up(lg) {
      height: 50vh;
      background-position: 50% 40%;
      background-size: cover;
      background-attachment: initial;
      top: -50px;
      left: -50px;
      right: -50px;
      width: calc(100% + 100px);
      opacity: 1;
    }
  }

  &--thumb {
    width: 100px;
    min-width: 100px;
    position: relative;
    margin-top: auto;
    height: 150px;

    @include media-breakpoint-up(md) {
      width: 150px;
      min-width: 150px;
      height: 230px;
    }

    @include media-breakpoint-up(lg) {
      width: 25%;
      min-width: 25%;
      margin-bottom: -50px;

      height: initial;
    }

    &--inner {
      padding-bottom: 150%;
      position: relative;
      width: 100%;
      height: 100%;
      overflow: hidden;
      height: 0;
      background: #484e50;
      border-radius: 10px;

      @include media-breakpoint-up(lg) {
        background: $dark-grey;
      }

      @include media-breakpoint-up(lg) {
        padding-bottom: 0;
        position: relative;
        padding-bottom: 150%;
      }
    }

    img {
      width: 100%;
      height: 100%;
      position: absolute;
      object-fit: cover;
    }
  }

  &--bio {
    // margin-left: 150px;
    margin-top: 20px;

    @include media-breakpoint-up(md) {
      margin-left: 170px;
      margin-top: 0;
    }

    @include media-breakpoint-up(lg) {
      margin-left: calc(25% + 50px);
    }

    .bio {
      width: 400px;
      max-width: 100%;
      overflow: hidden;
      max-height: calc(18px * 10);
      transition: max-height 0.4s ease;

      p {
        margin-bottom: 18px;
      }

      @include media-breakpoint-up(lg) {
        width: 600px;
        max-height: calc(22px * 10);

        p {
          margin-bottom: 22px;
        }
      }

      &.open {
        max-height: 1000px;
      }
    }

    &--read-more {
      color: $primary;
      font-weight: 700;
      cursor: pointer;
      margin-top: 20px;
      font-size: 12px;
      text-transform: uppercase;
      letter-spacing: 1px;
      display: inline-block;
    }
  }

  &--details {
    position: relative;
    // height: 300px;
    margin-top: auto;
    padding-left: 20px;
    padding-bottom: 40px;

    @include media-breakpoint-up(lg) {
      padding-bottom: 100px;
      padding-left: 50px;
      // padding-bottom: 0;
    }

    h1 {
      margin-bottom: 10px;
    }

    p {
      margin: 0;
    }
  }

  &--top {
    display: flex;
    height: #{300px - 65px};

    @include media-breakpoint-up(md) {
      height: calc(50vh - 65px);
    }

    @include media-breakpoint-up(lg) {
      height: 50vh;
    }

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 200px;
      width: 100%;
      z-index: -1;
    }
  }
}
