.page-wrap {
  padding: 80px 20px 20px 20px;
  max-width: 100vw;
  overflow-x: hidden;
  min-height: 100vh;
  max-height: 100vh;
  position: relative;
  overflow-y: auto;
  // padding-bottom: 120px;
  pointer-events: all;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding-top: calc(80px + env(safe-area-inset-top));
  // transform: translate3d(0, 0, 0);

  &::-webkit-scrollbar {
    display: none;
  }

  @include media-breakpoint-up(lg) {
    position: static;
    padding: 50px;
    // max-height: calc(100vh - 88px);
    max-height: 100vh;
    overflow-y: auto;
  }
}

.page {
  display: flex;
  flex-wrap: nowrap;

  .sidebar {
    width: 100%;

    @include media-breakpoint-up(lg) {
      width: 250px;
    }
  }

  .page-wrap {
    width: 100%;
    @include media-breakpoint-up(lg) {
      width: calc(100% - 250px);
    }
    // margin-top: 88px;
  }
}

.generic-wrap {
  @include media-breakpoint-down(md) {
    padding-bottom: 120px;
  }
}
