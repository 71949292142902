.search-form {
  display: flex;
  align-items: center;
  width: 400px;
  max-width: 100%;
  border-radius: 50px;
  overflow: hidden;

  input {
    height: 40px;
    border: none;
    background: rgba(255, 255, 255, 0.1);
    padding: 0 0 0 20px;
    font-size: 16px;
    border-radius: 0 !important;
    border: none;
    outline: none !important;
    color: white;
    width: 100%;

    &::placeholder {
      color: rgba(255, 255, 255, 0.2);
    }
  }

  &--clear {
    height: 40px;
    background: rgba(255, 255, 255, 0.1);
    border: none;
    outline: none !important;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      height: 20px;
      width: auto;
      opacity: 0;

      path {
        fill: rgba(255, 255, 255, 0.2);
      }
    }

    &.active {
      cursor: pointer;
      svg {
        opacity: 1;
      }
    }
  }

  .search-btn {
    width: 50px;
    height: 40px;
    background: rgba(255, 255, 255, 0.1);
    border: none;
    outline: none !important;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      height: 20px;
      width: auto;

      path {
        fill: rgba(255, 255, 255, 0.2);
      }
    }
  }
}
